import moment from 'moment';
import Swal from 'sweetalert2';

var petData;
var species;
var checkBoxElement;

export default {
    // Careful | in cases where the mixin and the component define 
    // the same property, the component's property will take precedence.
    data() {
        return {
            agreedPolicies: [],
            petInfo: [],
            familyPlan: [],
            selectedPlans: [],
            quoteTotals: [],
            submittingPayment: false,
            isQuoteLoaded: false,
            index: 0,
            question: null,
            plan: null
        }
    },
    computed: {
        owner(){
            return this.$store.getters.owner;
        },
        policies: function () {
            return this.$store.getters.policies;
        },
        pets() {
            return this.$store.getters.pets;
        },
        petsCount() {
            return this.$store.getters.pets.length;
        },
        paymentFrequency: {
            get() {
                return this.$store.getters.paymentFrequency;
            },
            set(value) {
                this.$store.commit("setPaymentFrequency", value);
            }
        },
        selectedPolicies: function () {
            return this.$store.getters.selectedPolicies;
        },
        employeeToken: function () {
            return this.$store.getters.employeeToken;
        },
        payOption() {
            return this.$store.getters.payOption;
        },
        contact: function () {
            return this.$store.getters.checkoutContact;
        },
        partner: function () {
            return this.$store.getters.partner;
        },
        isMetGen: function () {
            return this.$store.state.isMetGen;
        },
        isFamilyPlan: function () {
            return this.$store.getters.isFamilyPlan;
        },
        owner: function () {
            return this.$store.getters.owner;
        },
        taxable: function () {
            return this.$store.getters.checkoutContact.state === "KY";
        },
        discounts: function () {

            var discounts = [];
            var sels = this.$store.state.discounts.selection;
            var opts = this.$store.state.discounts.options;

            for (var i = 0; i < opts.length; i++) {
                if (sels.indexOf(opts[i].discountId.toString()) > -1) {
                    discounts.push({
                        description: opts[i].description,
                        discount: opts[i].name,
                        id: opts[i].discountId
                    })
                }
            }
            return discounts;
        },
        selectedPolicies: function () {
            return this.$store.getters.selectedPolicies;
        },
        routineCareLabel: function () {
            return this.$store.getters.isMetGen ? "Preventive Care" : "Routine Care"
        },
        leadId() {
            return this.$store.getters.leadId;
        },
        paymentRequestList() {
            return this.$store.getters.getPaymentRequestList;
        },
        currentPet() {
            return this.$store.getters.currentPet;
        },
        project() {
            return this.$store.getters.getProject;
        },
        step4SideImgSource(){
            return this.$store.getters.getStep4SideImgSource;
        },
        defaultSidebarImage() {
            const images = this.$store.getters.getDefaultDynamicContent.SidebarImages;
            return images && images.length > 0 ? images.find(image => image.SeqNum === 3) : null;
        },
        dynamicSidebarImage() {
            const images = this.$store.getters.getDynamicContent.SidebarImages;
            return images && images.length > 0 ? images.find(image => image.SeqNum === 3) : null;
        },
    },
    created() {
        this.$emit("setLoader", true, "Finalizing your policy");
        this.getQuote();
    },
    methods: {
        handleInputClick(index) {
            document.getElementById("agreedPolicies-chk-"+ index).click();
        },
        handleChecked(index){
            checkBoxElement = document.getElementById("agreedPolicies-chk-" + index)

            this.$nextTick(()=>{
                if(checkBoxElement.getAttribute("aria-checked")==="true"){
                    checkBoxElement.setAttribute("checked","checked");
                }
                else{
                    checkBoxElement.removeAttribute("checked");
                }
            })
        },
        async submitPayment() {
            var self = this;
            this.submittingPayment = true;

            petData = this.paymentRequestList[0].pets;
            if (petData[0].Species == 1) {
                species = "Dog";
            }
            else if (petData[0].Species == 2) {
                species = "Cat";
            }
            else {
                species = "Exotic";
            }

            if (checkBoxElement == undefined) {
                var ldo = {
                    eventName: 'quoteError',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        stepName: 'Purchase Policy',
                        ErrorMessage: 'Terms and conditions not agreed',
                        leadId: this.leadId,
                        petType: this.getPetsSpecies(this.pets),
                        standardWellness: this.getStandardWellness(this.selectedPolicies, this.isFamilyPlan, this.pets),
                        familyPlan: this.isFamilyPlan ? 'Yes' : 'No',
                    }
                };

                ldo = this.popullateFederalAnalyticProperty(ldo);

                self.$store.dispatch("trackAnalyticsEvent", {
                    eventType: "quoteError",
                    ldo: ldo
                });
            }
            else {
                var ldo = {
                    eventName: 'quoteComplete',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Quote',
                        paymentMethod: this.payOption,
                        providerName: this.pets[0].hasOwnProperty('provider') ? this.pets[0].provider : "",
                        leadId: this.leadId,
                        petType: this.getPetsSpecies(this.pets),
                        standardWellness: this.getStandardWellness(this.selectedPolicies, this.isFamilyPlan, this.pets),
                        familyPlan: this.isFamilyPlan ? 'Yes' : 'No',
                    }
                };

                ldo = this.popullateFederalAnalyticProperty(ldo);
                
                self.$store.dispatch("trackAnalyticsEvent", {
                    eventType: "quoteComplete",
                    ldo: ldo
                });
                // Optimization: google Tag manager : function  
                self.gTagReportConvertion();
                window.uetq.push('event', 'Quotes_CheckoutClick', { 'event_category': 'Lead', 'event_label': 'PetQuoteSite' });
                // console.log(window.uetq);
                fbq('track', 'PetQuoteSite_QuoteCheckout');
            }
            var planType = this.isFamilyPlan ? this.familyPlan : this.selectedPlans;

            if (this.agreedPolicies.length === planType.length) {
                var responses = [];
                let paymentRequest = null;

                paymentRequest = this.paymentRequestList

                if(!this.isFamilyPlan) {
                    paymentRequest.sort((a, b) => {
                        const petIndexA = a.petIndex;
                        const petIndexB = b.petIndex;
                        const discountA = this.$store.state.multiPetDiscounts[petIndexA];
                        const discountB = this.$store.state.multiPetDiscounts[petIndexB];

                        return discountA - discountB;
                    });
                } 
                
                await this.asyncForEach(paymentRequest, async (request) => {
                    let res = await self.$store.dispatch('submitPurchase', {
                        contact: request.contact,
                        pets: request.pets,
                        petIndex: request.petIndex,
                        token: request.token,
                        paymentFrequency: request.paymentFrequency,
                        paymentMethod: request.paymentMethod,
                        totalMultiPolicyDiscount: this.isFamilyPlan ? 1 : this.$store.state.multiPetDiscounts[request.petIndex]
                    });
                    responses.push(res);
                });

                if (responses[0].error === null) {

                    let policyNumbers = responses.map(function (obj) { return obj.policyNumber.slice(3, -3) }).join(":");
                    let policyIDs = responses.map(function (obj) { return obj.policyId }).join(":");
                    let policyPremiums = "";
                    let policies = self.$store.getters.policies;
                    if (this.paymentFrequency === "Annually")
                        policyPremiums = policies.map(function (obj) { return obj.plan.firstMonthPremium }).join(":");
                    else
                        policyPremiums = policies.map(function (obj) { return obj.plan.monthlyPremium }).join(":");

                    let ldo = {
                        eventName: 'quotePurchase',
                        attributes: {
                            application: 'MLPI',
                            form: 'Pet Insurance Quote',
                            policyNum: policyNumbers,
                            policyID: policyIDs,
                            policyPremium: policyPremiums,
                            paymentMethod: this.payOption,
                            providerName: this.pets[0].hasOwnProperty('provider') ? this.pets[0].provider : "",
                            leadId: this.leadId,
                            petType: this.getPetsSpecies(this.pets),
                            standardWellness: this.getStandardWellness(this.selectedPolicies, this.isFamilyPlan, this.pets),
                            familyPlan: this.isFamilyPlan ? 'Yes' : 'No',
                        }
                    };

                    ldo = this.popullateFederalAnalyticProperty(ldo);

                    self.$store.dispatch("trackAnalyticsEvent", {
                        eventType: "quotePurchase",
                        ldo: ldo
                    });

                    const resPolicies = responses;
                    return self.saveConfirmation(resPolicies)
                        .then(() => {
                            self.$emit("setLoader", false, "");
                            self.$store.commit("setPaymentRequestList", null);

                            return true;
                        })
                        .catch(err => {
                            console.log(err);
                            let ldo = {
                                eventName: 'quoteError',
                                attributes: { 
                                application: 'MLPI',
                                    form: 'Pet Insurance Quote',
                                    stepName: 'Purchase Policy',
                                    ErrorMessage: err.message,
                                    leadId: this.leadId,
                                    petType: this.getPetsSpecies(this.pets),
                                    standardWellness: this.getStandardWellness(this.selectedPolicies, this.isFamilyPlan, this.pets),
                                    familyPlan: this.isFamilyPlan ? 'Yes' : 'No',
                                }
                            };

                            ldo = this.popullateFederalAnalyticProperty(ldo);

                            self.$store.dispatch("trackAnalyticsEvent",{
                                eventType: "quoteError",
                                ldo: ldo
                            });
                        });
                }else{
                    let ldo = {
                        eventName: 'quoteError',
                        attributes: { 
                        application: 'MLPI',
                            form: 'Pet Insurance Quote',
                            stepName: 'Purchase Policy',
                            ErrorMessage: responses[0].error.Message,
                            leadId: this.leadId,
                            petType: this.getPetsSpecies(this.pets),
                            standardWellness: this.getStandardWellness(this.selectedPolicies, this.isFamilyPlan, this.pets),
                            familyPlan: this.isFamilyPlan ? 'Yes' : 'No',
                        }
                    };

                    ldo = this.popullateFederalAnalyticProperty(ldo);

                    self.$store.dispatch("trackAnalyticsEvent",{
                        eventType: "quoteError",
                        ldo: ldo
                    });

                    Swal.fire({
                        icon: "error",
                        title: "Error Purchasing Policy",
                        text: "An error occurred while purchasing your policy. Please try again later.",
                    }).then(() => {
                        window.location.href = "https://metlifepetinsurance.com";
                    });
                }
            } else {
                this.$emit("setLoader", false, "");
                Swal.fire({
                    icon: 'warning',
                    iconHtml:'<span role="img" aria-label="warning">!</span>',
                    title: "Pet policy approval missing",
                    text: "You need to approve all the policies before submitting a purchase. Review each policy and confirm by filling the confirmation checkbox.",
                    inputAttributes: {
                        id: 'swalApprovalMissing'
                    },
                    willOpen: () => {
                        let swalImage = document.getElementsByClassName("swal2-image");
                         swalImage[0].setAttribute("alt", "");
                    },
                    didRender: () => {
                        let icon = document.getElementsByClassName('swal2-icon-content');
                        icon[0].setAttribute('aria-hidden', 'true')
                    }
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.focusCheckbox();
                    }
                });
            }
            return false;
        },
        focusCheckbox() {
            this.$nextTick(() => {
                const interval = setInterval(()=>{
                    if(this.petInfo.length < 2 || this.isFamilyPlan) {
                        let checkbox = document.getElementById('agreedPolicies-chk-native-0');
                        if(checkbox) {
                            setTimeout(()=> {
                                checkbox.focus();
                            }, 500);
                            clearInterval(interval);
                        } 
                    } else {
                        let checkbox;
                        for (let index = 0; index < this.petInfo.length; index++) {
                            checkbox = document.getElementById('agreedPolicies-chk-native-' + index);
                            if(checkbox) {
                                if(!checkbox.checked) {
                                    break;
                                }
                            }
                        }
                        setTimeout(()=> {
                            checkbox.focus();
                        }, 500);
                        clearInterval(interval);
                    }   
                }, 100);
            });
        },
        saveConfirmation: function (resPolicies) {
            return new Promise((resolve, reject) => {
                try {
                    var self = this;

                    this.$store.commit('setConfirmationProp', {
                        prop: 'totals',
                        val: {
                            subTotal: self.getSubTotal(),
                            totalTax: self.getTotalTaxes(),
                            totalFees: self.getTotalFees(),
                            totalDiscount: self.getTotalDiscounts(),
                            totalTotal: self.getTotalAmount(),
                            splitTotals: (() => {
                                let totals = [];
                                if (this.paymentFrequency === "Annually") {
                                    if (this.taxable) {
                                        for (var i = 0; i < Object.keys(this.policies).length; i++) {
                                            let total = 0;
                                            total += ((this.policies[i].plan.monthlyPremium + this.policies[i].plan.monthlyTax) * 11);
                                            total += (this.policies[i].plan.firstMonthPremium + this.policies[i].plan.monthlyTax);
                                            total -= Number(this.policies[i].plan.paymentSchedule.totalDiscount);
                                            totals.push(total);
                                        }
                                    } else {
                                        for (var i = 0; i < Object.keys(this.policies).length; i++) {
                                            let total = 0;
                                            total += (this.policies[i].plan.monthlyPremium * 11);
                                            total += (this.policies[i].plan.firstMonthPremium);
                                            total -= Number(this.policies[i].plan.paymentSchedule.totalDiscount);
                                            totals.push(total);
                                        }
                                    }
                                } else {
                                    for (var i = 0; i < Object.keys(this.policies).length; i++) {
                                        if (this.taxable) {
                                            totals.push(this.policies[i].plan.firstMonthPremium + this.policies[i].plan.monthlyTax);
                                        } else {
                                            totals.push(this.policies[i].plan.firstMonthPremium);
                                        }
                                    }
                                }
                                return totals;
                            })()
                        }
                    })

                    this.$store.commit('setConfirmationProp', {
                        prop: 'discounts',
                        val: (function () {
                            let discounts = [];
                            for (var i = 0; i < self.discounts.length; i++) {
                                discounts.push({
                                    description: self.discounts[i].description,
                                    discount: self.getOpinionatedDiscountById(self.discounts[i].id, self.discounts[i].discount)
                                })
                            }
                            return discounts;
                        }())
                    })

                    this.$store.commit('setConfirmationProp', {
                        prop: 'contact',
                        val: (function () {
                            self.contact.email = self.owner.email;
                            return self.contact;
                        }())
                    })

                    setTimeout(() => {
                        this.$store.commit('saveConfirmation')
                        setTimeout(() => {
                            this.$store.state.policySold = true;
                            this.$store.dispatch("createOrUpdateLeadCustomerInfo")
                                .then(() => {
                                    const promises = resPolicies.map(resPolicy => {
                                        return this.$store.dispatch("createOrUpdateLeadPolicySold", resPolicy.policyId);
                                    });
                                      
                                    Promise.all(promises)
                                        .then(() => {
                                          resolve("Policy Sold");
                                        });
                                });
                        }, 500);
                    }, 50);
                } catch (error) {
                    var ldo = {
                        eventName: 'quoteError',
                        attributes: {
                            application: 'MLPI',
                            form: 'Pet Insurance Quote',
                            stepName: 'Purchase Policy',
                            ErrorMessage: 'Error Purchasing Policy',
                            leadId: this.leadId,
                            petType: this.getPetSpecie(this.currentPet.species)
                        }
                    };

                    ldo = this.popullateFederalAnalyticProperty(ldo);
    
                    digitalData.eventTrack('quoteError', ldo);
                    
                    reject(error.message);
                }
            });
        },
        setConfirmPolicy: function (policy, i) {
            let confirmPolicyObject = {};
            let annualSavings = 0;

            if (this.paymentFrequency != 'Annually') {
                if (this.employeeToken && (this.partner.billingOptions == 'payrollDeduct' || this.payOption == 'payrollDeduct')) {
                    confirmPolicyObject.planRate = `${this.formatPrice(policy.plan.paymentSchedule?.payments[1].total)} / per month ${this.owner.state !== 'GA' ? '' : `(${this.formatPrice(policy.plan.firstMonthPremium)} / first month – excludes $3 installment fee)` }`;
                } else {
                    confirmPolicyObject.planRate = `${this.formatPrice(policy.plan.paymentSchedule?.payments[1].total)} / per month (${this.formatPrice(policy.plan.firstMonthPremium)} / ${ this.formatPrice(policy.plan.paymentSchedule?.payments[1].total) === this.formatPrice(policy.plan.firstMonthPremium) ? 'first month' : this.owner.state !== 'GA' ? 'first month includes $12 enrollment fee' : 'first month – excludes $3 installment fee' })`;
                }
            } else {
                confirmPolicyObject.planRate = policy.plan.paymentSchedule.total !== undefined ? `${this.formatPrice(policy.plan.paymentSchedule.total)} / per year` : "";
            }                
            confirmPolicyObject.annualLimit = policy.plan.termLimit;
            confirmPolicyObject.reimbursement = `${(policy.plan.reimbursement * 100)}%`;
            this.isFamilyPlan
                ? confirmPolicyObject.routineCare = this.selectedPolicies.find(x => x.planId = policy.plan.legacyPlanId).planDetails.rider.displayName
                : confirmPolicyObject.routineCare = this.selectedPolicies[i].planDetails.rider.displayName;
            confirmPolicyObject.deductible = policy.plan.deductible;
            confirmPolicyObject.accidentalCoverageStart = this.getAccidentCoverageBeginsDate();
            confirmPolicyObject.illnessCoverageStart = this.getIllnessCoverageBeginsDate(i);
            var planDetails = this.isFamilyPlan ? this.selectedPolicies[0].planDetails : this.selectedPolicies[i].planDetails;
            annualSavings = planDetails.totalMonthlyPaid - planDetails.totalAnnuallyPaid;
            confirmPolicyObject.annualDiscountAmount = annualSavings;

            return confirmPolicyObject;
        },
        getAccidentCoverageBeginsDate() {
            var effectiveDate = this.$store.getters.effectiveDate;

            if (effectiveDate !== undefined) {
                effectiveDate = moment(effectiveDate).toISOString();
                if (moment().isBefore(moment(effectiveDate))) {
                    return moment(effectiveDate).format("MM/DD/YYYY");
                }
            }

            if (this.partner.billingOptions == 'payrollDeduct' || this.payOption == 'payrollDeduct') {
                //return the first day of the next month
                return moment().add(1, 'M').startOf('month').format('MM/DD/YYYY');
            }
            else {
                //get the next day
                return moment().add(1, 'days').format('MM/DD/YYYY');
            }
        },
        getIllnessCoverageBeginsDate(i) {
            var effectiveDate = this.$store.getters.effectiveDate;
            var pets = this.$store.getters.pets;

            if (pets.length > 0) {
                if (pets[i].providerId != null) {
                    return moment(effectiveDate).format("MM/DD/YYYY");
                }
            }

            var pets = this.$store.getters.pets;

            if (pets.length > 0) {
                if (pets[i].providerId != null) {
                    return moment(effectiveDate).format("MM/DD/YYYY");
                }
            }

            if (effectiveDate !== undefined) {
                effectiveDate = moment(effectiveDate).toISOString();
                if (moment().isBefore(moment(effectiveDate))) {
                    return moment(effectiveDate).add(14, 'days').format("MM/DD/YYYY");
                }
            }

            if (this.partner.billingOptions == 'payrollDeduct' || this.payOption == 'payrollDeduct') {
                //return the 15th day of next month
                return moment().add(1, 'M').startOf('month').add(14, 'days').format('MM/DD/YYYY');
            }
            else {
                return moment().add(15, 'days').format('MM/DD/YYYY');
            }
        },
        getSubTotal: function () {
            let tt = 0;
            if (this.paymentFrequency === "Annually") {
                for (var i = 0; i < Object.keys(this.policies).length; i++) {
                    tt += this.policies[i].plan.paymentSchedule.totalPremium;
                }
            } else {
                for (var i = 0; i < Object.keys(this.policies).length; i++) {                   
                    let payments = this.policies[i].plan.paymentSchedule.payments[1];
                    if (payments) {
                        tt += this.isMetGen ? payments.totalPremium : payments.totalPremium;
                    }
                }

            }
            return tt;
        },
        getFeesLabel() {
            const fees = this.policies[0]?.plan?.paymentSchedule?.feeTotalBreakdown;

            if(fees) {
                if (this.paymentFrequency === 'Monthly' && (fees.some(fee => fee.description === "MetLife Monthly Installment Fee") ||  fees.some(fee => fee.description === "IAIC Monthly Installment Fee"))) {
                    return "Installment Fees"
                }

                if (this.paymentFrequency === 'Annually' && (fees.some(fee => fee.description === "MetLife Enrollment Fee") || fees.some(fee => fee.description === "IAIC Enrollment Fee"))) {
                    return "Enrollment Fees";
                }
            } 

            return "Fees";
        },
        sortedFees(feeTotalBreakdown) {
            const fees = [...feeTotalBreakdown];

            if(fees.length === 1) return fees;

            const feeOrder = ["MetLife Monthly Installment Fee", "MetLife Enrollment Fee"]; 
            
            fees.sort((a, b) => { 
                const aIndex = feeOrder.indexOf(a.description); 
                const bIndex = feeOrder.indexOf(b.description); 
                return aIndex - bIndex; 
            });

            return fees;
        },
        getFeesShortDescription(description) {
            switch(description) {
                case "MetLife Monthly Installment Fee":
                    return "Installment Fees";
                case "MetLife Enrollment Fee":
                    return "Enrollment Fees";
                case "IAIC Monthly Installment Fee":
                    return "Installment Fees";
                case "IAIC Enrollment Fee":
                    return "Enrollment Fees";
                default:
                    return "Fees";
            }
        },
        getTotalFees: function () {
            let tt = 0;
            if (this.paymentFrequency === "Annually") {
                for (var i = 0; i < Object.keys(this.policies).length; i++) {
                    tt += this.policies[i].plan.paymentSchedule.totalFee;
                }

            } else {
                for (var i = 0; i < Object.keys(this.policies).length; i++) {
                    tt += this.policies[i].plan.paymentSchedule?.payments[1].totalFee;
                }
            }
            return tt;
        },
        getTotalDiscounts: function () {
            let tt = 0;
            if (this.paymentFrequency === "Annually") {
                for (var i = 0; i < Object.keys(this.policies).length; i++) {
                    tt += this.policies[i].plan.paymentSchedule.totalDiscount;
                }

            } else {
                for (var i = 0; i < Object.keys(this.policies).length; i++) {
                    tt += this.policies[i].plan.paymentSchedule?.payments[1].totalDiscount;
                }
            }
            return tt;
        },
        getTotalTaxes: function () {
            let tt = 0;

            if (!this.taxable) {
                return 0;
            } else {
                if (this.paymentFrequency === "Annually") {
                    for (var i = 0; i < Object.keys(this.policies).length; i++) {
                        tt += this.policies[i].plan.paymentSchedule.totalTax;
                    }
                } else {
                    for (var i = 0; i < Object.keys(this.policies).length; i++) {
                        tt += this.policies[i].plan.paymentSchedule?.payments[1].totalTax;
                    }

                }
                return tt;
            }
        },
        getTotalAmount: function () {
            let tt = 0;
            if (this.paymentFrequency === "Annually") {
                if (this.policies) {
                    for (var i = 0; i < Object.keys(this.policies).length; i++) {
                        tt += this.policies[i].plan.paymentSchedule.total;
                    }
                }
            } else {
                if (this.policies) {
                    for (var i = 0; i < Object.keys(this.policies).length; i++) {
                        if (this.policies[i].plan.paymentSchedule?.payments[1]) {
                            tt += this.policies[i].plan.paymentSchedule?.payments[1].total;
                        }
                    }
                }
            }
            return tt;
        },

        populateConfirmationDetails() {
            this.petInfo = [], this.familyPlan = [], this.selectedPlans = [], this.quoteTotals = [];

            var self = this;

            this.pets.map(function (pet) {
                self.petInfo.push(pet);
            });
            let i = 0
            this.policies.forEach(function (policy) {
                var planType = self.isFamilyPlan ? self.familyPlan : self.selectedPlans;
                planType.push(self.setConfirmPolicy(policy, i));
                i++;
            });

            let quoteTotalDetails = {
                subTotal: self.getSubTotal(),
                fees: self.getTotalFees(),
                discounts: self.getTotalDiscounts(),
                taxes: self.getTotalTaxes(),
                total: self.getTotalAmount().toFixed(2).toString()
            };
            this.quoteTotals.push(quoteTotalDetails);
        },
        setConfirmPolicyPaymentFrequency(paymentFrequency,index) {
            if(index==0 || index>0){
                this.clickedRadioGroup = paymentFrequency+"Radio"+index;
            }
            else{
                this.clickedRadioGroup = paymentFrequency+"Radio";
            }
            
            this.paymentFrequency = paymentFrequency;               
            this.$emit("setLoader", true, "Updating your Quote", this.focusRadio);
            this.getQuote(paymentFrequency);
        },
        // pickFocusRadio(paymentFrequency) {
        //     if(paymentFrequency === "Annually") {
        //         this.focusRadio("AnnuallyRadio")
        //     }
        //     if(paymentFrequency === "Monthly") {
        //         this.focusRadio("MonthlyRadio");
        //     }
        // },
        focusRadio() {
            let radioId = this.clickedRadioGroup;
            const interval = setInterval(()=>{
                if(document.getElementById(radioId)) {
                    this.$nextTick(()=>document.getElementById(radioId).focus());
                    clearInterval(interval);
                }
            }, 100);
        },
        getOpinionatedDiscountById: function (id, text) {
            let splitText = text.split(' ');
            for (var i = 0; i < splitText.length; i++) {
                // Contains a $ or a %
                if (splitText[i].search(/^-?\$?[0-9]*\.?([0-9]{2})?%?$/) > -1) {
                    if (splitText[i].indexOf('$') > -1) {
                        // Is a dollar amount. Multiply by the amount of policies
                        return '- $' + (parseInt(splitText[i].substr(1)) * Object.keys(this.policies).length).toFixed(2);
                    } else if (splitText[i].indexOf('%') > -1) {
                        // Is a percentage. Multiply the subtotal with the percentage
                        return "- $" + ((splitText[i].replace('%', '') / 100) * this.subTotal).toFixed(2);
                    }
                } else if (text.indexOf('Welfare') > -1) {
                    // Specific opinionated case for Animal Welfare discount since no amount is present.
                    return "- $" + (0.1 * this.subTotal).toFixed(2);
                }
            }
        },
        redirectToView(step) {
            this.routerPush(step);
        },
        async redirectToOtherLink(url){
            await Swal.fire({
                title: "You are about to open an URL in a New Tab, Proceed to new tab?",
                showDenyButton: true, 
                confirmButtonText: "Yes",
                denyButtonText: "No",
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("analyticsExit");
                    window.open(url);
                }
            })
        },
        getQuote(paymentFrequency) {
            var self = this;
            let getQuotePromises = [];
            let totalPoliciesPushed = 0;

            this.selectedPolicies.forEach(function (policyValue, i) {
                var petRequest = [];

                if (self.isFamilyPlan) {
                    self.pets.map(function (pet) {
                        let petObject = self.setGetQuotePetRequest(pet);
                        petRequest.push(petObject);
                    });
                } else {
                    self.pets.map(function (pet, index) {
                        if (index == policyValue.petArrayIndex) {
                            let petObject = self.setGetQuotePetRequest(pet);
                            petRequest.push(petObject);
                        }
                    })
                }

                getQuotePromises.push(async function () {
                    let totalMultiPolicyDiscount = self.$store.state.isFamilyPlan ? 1 : self.$store.state.multiPetDiscounts[i];
                    totalPoliciesPushed++;
                    let res = await self.$store.dispatch('getQuote', {
                        pets: petRequest,
                        plan: {
                            TermLimit: policyValue.limit,
                            Deductible: policyValue.deductible,
                            Reimbursement: policyValue.reimbursement,
                            RiderId: policyValue.riderId,
                            LegacyPlanId: policyValue.planId,
                            PetIndex: policyValue.petArrayIndex,
                            FilingId: policyValue.planDetails.filingId
                        },
                        address: {
                            StreetAddress: self.contact.address1,
                            StreetAddress2: self.contact.address2,
                            CityName: self.contact.city,
                            StateAbbreviation: self.contact.state,
                            ZipCode: self.contact.zip
                        },
                        totalMultiPolicyDiscount: totalMultiPolicyDiscount
                    });
                    return res;
                });
            });

            Promise.all(getQuotePromises.map(callback => callback()))
                .then(() => {
                    this.populateConfirmationDetails();
                    this.$store.commit("setCurrentPage","Confirmation Page");
                    this.isQuoteLoaded = true;
                    this.$emit("setLoader", false, "");
                    // this.pickFocusRadio(paymentFrequency);
                })
                .catch(e => {
                    console.log(e.message);
                });
        },
        setGetQuotePetRequest(pet) {
            let petObject = {};

            petObject.PetName = pet.name;
            petObject.SpeciesId = pet.species;
            petObject.Gender = pet.gender;
            petObject.BreedId = pet.breed != "" ? pet.breed : pet.species == "1" ? "54" : "2";
            petObject.Age = pet.age;
            petObject.Weight = pet.weight;
            petObject.IsMixedBreed = pet.mixed;

            return petObject;
        },
        // Optimization: google Tag manager : function  
        gTagReportConvertion(url) {
            var callback = function () {
                if (typeof (url) != 'undefined') {
                    window.location = url;
                }
            };
            gtag('event', 'conversion', {
                'send_to': 'AW-381302213/ltCSCKTgzvMCEMXr6LUB',
                'event_callback': callback,
                'value': 0,
                'currency': 'USD',
            });
            gtag('event', 'conversion', {
                'allow_custom_scripts': true,
                'send_to': 'DC-1524815/petin0/petqu0+standard',
                'value': 0,
                'currency': 'USD',
            });
            return false;
        },
        async asyncForEach(array, callback) {
            for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
            }
        }
    },
}
<template>
    <div>
        <v-form v-show="partner.id">
            <v-row>
                <v-col>
                    <v-card flat v-show="showEmploymentInfo() && showEditEmployment()">
                        <h2 class="mt-9 mb-8">Employment Information</h2>
                        <span>Please confirm your employer information is correct prior to entering payment information.</span>
                        <div v-if="!showPartnerSearch" class="my-4">
                            <span class="mb-6 d-block text-subtitle-2 font-weight-black">{{ partner.name }}</span>
                            <v-btn class="mb-2" large outlined color="primary" @click="setShowPartnerSearch()">
                                <v-icon>mdi-pencil</v-icon> EDIT EMPLOYER INFORMATION
                            </v-btn>
                        </div>
                        <div v-else class="my-4">
                            <user-employeer ref="userEmployer" @setValidEmployer="setValidEmployer"></user-employeer>

                            <v-btn large outlined color="primary" class="mb-4" @click="confirmEmployer()" :disabled="!validEmployer">
                                CONFIRM EMPLOYER INFORMATION
                            </v-btn>
                            
                            <span class="d-block font-weight-thin d-flex flex-row align-start caption"><v-icon class=" mt-n1 mr-1 ">mdi-alert-circle</v-icon> Any changes made to your employer may affect your quote and billing options.</span>
                        </div>
                    </v-card>
                    <v-card flat>
                        <v-overlay z-index="3" absolute :value="showPartnerSearch" color="white" opacity="0.6"></v-overlay>
                        <v-overlay z-index="3" absolute :value="showPaymentOverlay" color="white" opacity="0.6"></v-overlay>
                        <v-form v-show="partner.billingOptions == 'directBill' || payOption == 'creditCard'">                           
                            <div v-if="partner.billingOptions == 'directBill' || payOption == 'creditCard'">
                                <v-text-field v-if="partner.isEmpIdRequired && !hideGSSPEmployeeId"
                                    v-model="contact.employeeId"
                                    label="Employee Id" 
                                    @keydown="clearErrorOnChange"
                                    @input="$v.contact.employeeId.$touch()" 
                                    :error-messages="EmployeeIdErrors"
                                ></v-text-field> 
                                <div class="v-messages theme--light" v-if="employeeIdFailedAttempt && partner.isEmpIdRequired && !hideGSSPEmployeeId &&  this.employeeSearchCount <= 3 ">
                                    <div class="v-messages__wrapper">
                                        <div id="employeeIdErrorMsj" class="v-messages__message d-flex flex-row align-start">
                                            <v-icon small class="mr-2 error--text" large>mdi-alert-circle</v-icon>
                                            <p class="body-2 error--text"> Attempt {{  this.employeeSearchCount }}: There was an issue validating your eligibility. Please check the information and try again.</p>
                                        </div>
                                    </div>
                                </div>
                                <v-text-field v-if="partner.isSsnRequired && !hideGSSPSSN"
                                    ref="ssnField"
                                    v-model="contact.ssn" 
                                    :label="ssnLabel" 
                                    @keydown="clearSSNErrorOnChange"
                                    @input="$v.contact.ssn.$touch()" 
                                    :error-messages="SSNErrors" 
                                    type="password" 
                                    v-mask="ssnMask"
                                ></v-text-field>
                                <div class="v-messages theme--light" v-if="ssnFailedAttempt && partner.isSsnRequired && !hideGSSPSSN">
                                    <div class="v-messages__wrapper">
                                        <div id="employeeIdErrorMsj" class="v-messages__message d-flex flex-row align-start">
                                            <v-icon small class="mr-2 error--text" large>mdi-alert-circle</v-icon>
                                            <p class="body-2 error--text"> Attempt {{  this.employeeSearchCount }}: {{ this.employeeSearchCount <= 3 ? this.ssnFailedFormat ? 'Please enter a valid SSN' : 'There was an issue validating your eligibility. Please check the information and try again.' : 'Invalid SSN - Please call Pet Advocate number listed in top of the screen.' }}</p>
                                        </div>
                                    </div>
                                </div>
                                <vue-recaptcha
                                    class="mt-2"
                                    ref="recaptcha"
                                    :sitekey="siteKey"
                                    @verify="onCaptchaVerified"
                                    badge="inline"
                                    size="invisible"
                                    v-if="ssnFunctActive()"
                                />

                                <div class="v-messages theme--light" v-if="contactBillingEmployeeIdTipsBlockHtml">
                                    <div class="v-messages__wrapper">
                                        <div id="breedTypeMessages" class="v-messages__message d-flex flex-row align-start">
                                            <v-icon class="mr-2 info--text" large>mdi-information-slab-circle</v-icon>
                                            <span class="body-2" v-html="contactBillingEmployeeIdTipsBlockHtml"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </v-form>
                    </v-card>

                    <v-card flat v-if="partner.billingOptions === 'both'" class="mt-4">
                        <v-overlay z-index="3" absolute :value="showPartnerSearch" color="white" opacity="0.6"></v-overlay>
                        <v-form>
                            <v-row>
                                <v-col>
                                    <h2 class="mb-8 mt-9">Payment Method</h2>
                                </v-col>
                            </v-row>

                            <div class="btn-toggle-container mt-4">
                                <v-form ref="PaymentMethodForm" v-model="formValidPaymentMethod" lazy-validation>
                                    <v-radio-group
                                        row
                                        aria-labelledby= "PaymentMethod PaymentMethodRequired"
                                        v-model="paymentMethod" 
                                        :class="[formValidPaymentMethod ? 'valid':'invalid', 'my-0']" 
                                        :aria-errormessage="formValidPaymentMethod ? '':'paymentMethodErrors'"
                                        aria-describedby="payment-method-message"
                                        :rules="rules"
                                        @change="paymentSelected"
                                    >
                                        <v-radio v-for="(method, methodIndex) in paymentMethods"
                                            :key="method.id" 
                                            :aria-labelledby= "'PaymentMethod PaymentMethodRequired btn-payment-' + method.id +' payment-'+method.id+'-label'"
                                            :value="method.id"
                                            :label="method.name"
                                            :aria-pressed="paymentMethod === method.id ? 'true':'false'" 
                                            aria-describedby="paymentMessages"
                                            @blur="localValidatePaymentMethod(methodIndex)"
                                        >
                                            <template v-slot:label>
                                                <span :id="'payment-'+method.id+'-label'">{{method.name}}</span>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                    <label for="payment-method" id="PaymentMethod">
                                        Quote may change depending on payment method.
                                    </label>
                                </v-form>
                            </div>
                        </v-form>
                    </v-card>                   
                </v-col>
            </v-row>
        </v-form>
        <v-dialog class="swal-mimic" v-model="showAttempsDialog" max-width="1000" persistent>
            <v-card class="swal-mimic" :class=" $vuetify.breakpoint.mdAndUp ? 'pa-10':'pa-0'">
                <v-card-title class="d-flex flex-column">     
                    <v-btn 
                        style="position: absolute;"
                        class="align-self-end"
                        icon
                        @click="this.closeAttempsDialog"
                        aria-label="Close Dialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>               
                    <v-icon class="mb-6" :class="$vuetify.breakpoint.mdAndUp ? 'xxxx-large':'xx-large'">$dialogQuestion</v-icon>
                    <span class="title-text mb-6">
                        Ruh-Roh! We weren't able to validate your employment with {{ partner.name }}
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <p>Don't worry, your quote has been saved. Please choose between these 2 options:</p>
                        </v-col>
                        <v-col cols="12">
                            <v-btn class="btn-wrap btn-vertical btn-loose btn-lower-case" block color="primary" href="tel:855-705-1368">
                                <v-row class="d-flex flex-row align-center">
                                    <v-col class="flex-grow-0">
                                        <v-icon x-large>mdi-face-agent</v-icon>
                                    </v-col>
                                    <v-col class="d-flex flex-column flex-grow-1 text-left">
                                        <span :class="$vuetify.breakpoint.mdAndUp ? 'text-body-1':'text-body-2'">Our team can help! Give us a call at 855-705-1368. We are available:  </span>
                                        <div class="d-flex flex-column caption">
                                            <span>Monday-Friday: 8am - 9pm EST</span>
                                            <span>Saturday: 10am - 7pm EST</span>
                                            <span>Sunday: 10am - 7pm EST</span>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <v-btn class="btn-wrap btn-vertical btn-loose btn-lower-case" @click="goBackToRetail" block>
                                <v-row class="d-flex flex-row align-center">
                                    <v-col  class="flex-grow-0">
                                        <v-icon x-large>mdi-restart</v-icon>
                                    </v-col>
                                    <v-col class="d-flex flex-column flex-grow-1 text-left">
                                        <span :class="$vuetify.breakpoint.mdAndUp ? 'text-body-1':'text-body-2'">If you Aren't an Employee of {{ partner.name }}: click to shop for a policy not associated with {{ partner.name }} *  </span>                                
                                    </v-col>
                                </v-row>
                            </v-btn>
                        </v-col>
                        <v-col cols="12">
                            <span> * Enroll now without the employer group discount and switch to credit card payment (You may still apply your discount in the future).</span>
                        </v-col>

                    </v-row>
                    
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-card flat v-show="showEmploymentInfo()">
            <v-overlay z-index="3" absolute :value="showPartnerSearch" color="white" opacity="0.6"></v-overlay>
            <v-overlay z-index="3" absolute :value="showPaymentOverlay" color="white" opacity="0.6"></v-overlay>
            <v-form v-show="partner.billingOptions == 'payrollDeduct' || payOption == 'payrollDeduct'">
                <v-row>
                    <v-col>
                        <h2 class="mt-9 mb-8">Employee Deduction</h2>
                    </v-col>
                </v-row>
                <span>Please confirm your information is correct. We'll use this information to deduct payment from your payroll.</span>

                <div v-if="partner.billingOptions == 'payrollDeduct' || payOption == 'payrollDeduct'">
                    <v-text-field v-if="partner.isEmpIdRequired && !hideGSSPEmployeeId"
                        v-model="contact.employeeId" 
                        label="Employee Id" 
                        @keydown="clearErrorOnChange"
                        @input="$v.contact.employeeId.$touch()"
                        :error-messages="EmployeeIdErrors"
                    ></v-text-field>
                    <div class="v-messages theme--light" v-if="employeeIdFailedAttempt && partner.isEmpIdRequired && !hideGSSPEmployeeId && this.employeeSearchCount <= 3 ">
                        <div class="v-messages__wrapper">
                            <div id="employeeIdErrorMsj" class="v-messages__message d-flex flex-row align-start">
                                <v-icon small class="mr-2 error--text" large>mdi-alert-circle</v-icon>
                                <p class="body-2 error--text"> Attempt {{  this.employeeSearchCount }}: There was an issue validating your eligibility. Please check the information and try again.</p>
                            </div>
                        </div>
                    </div>
                    <v-text-field v-if="partner.isSsnRequired && !hideGSSPSSN"
                        ref="ssnField"
                        v-model="contact.ssn" 
                        :label="ssnLabel" 
                        @keydown="clearSSNErrorOnChange"
                        @input="$v.contact.ssn.$touch()" 
                        :error-messages="SSNErrors" 
                        type="password" 
                        v-mask="ssnMask"
                    ></v-text-field>
                    <div class="v-messages theme--light" v-if="ssnFailedAttempt && partner.isSsnRequired && !hideGSSPSSN">
                        <div class="v-messages__wrapper">
                            <div id="employeeIdErrorMsj" class="v-messages__message d-flex flex-row align-start">
                                <v-icon small class="mr-2 error--text" large>mdi-alert-circle</v-icon>
                                <p class="body-2 error--text"> Attempt {{  this.employeeSearchCount }}: {{ this.employeeSearchCount <= 3 ? this.ssnFailedFormat ? 'Please enter a valid SSN' : 'There was an issue validating your eligibility. Please check the information and try again.' : 'Invalid SSN - Please call Pet Advocate number listed in top of the screen.' }}</p>
                            </div>
                        </div>
                    </div>
                    <vue-recaptcha
                        class="mt-2"
                        ref="recaptcha"
                        :sitekey="siteKey"
                        @verify="onCaptchaVerified"
                        badge="inline"
                        size="invisible"
                        v-if="ssnFunctActive()"
                    />

                    <div class="v-messages theme--light" v-if="contactBillingEmployeeIdTipsBlockHtml">
                        <div class="v-messages__wrapper">
                            <div id="breedTypeMessages" class="v-messages__message d-flex flex-row align-start">
                                <v-icon class="mr-2 info--text" large>mdi-information-slab-circle</v-icon>
                                <span class="body-2" v-html="contactBillingEmployeeIdTipsBlockHtml"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </v-form>
        </v-card>    

        <v-card flat v-show="captureSSN">
            <v-overlay z-index="3" absolute :value="showPartnerSearch" color="white" opacity="0.6"></v-overlay>
            <v-overlay z-index="3" absolute :value="showPaymentOverlay" color="white" opacity="0.6"></v-overlay>
            <v-form class="mt-6">                           
                <div>  
                    <v-text-field v-if="captureSSN"
                        ref="ssnField"
                        v-model="contact.ssn" 
                        :label="ssnLabel" 
                        @keydown="clearSSNErrorOnChange"
                        @input="$v.contact.ssn.$touch()" 
                        :error-messages="SSNErrors" 
                        type="password" 
                        v-mask="ssnMask"
                    ></v-text-field>
                    <div class="v-messages theme--light" v-if="ssnFailedAttempt">
                        <div class="v-messages__wrapper">
                            <div id="employeeIdErrorMsj" class="v-messages__message d-flex flex-row align-start">
                                <v-icon small class="mr-2 error--text" large>mdi-alert-circle</v-icon>
                                <p class="body-2 error--text"> Attempt {{  this.employeeSearchCount }}: {{ this.employeeSearchCount <= 3 ? this.ssnFailedFormat ? 'Please enter a valid SSN' : 'There was an issue validating your eligibility. Please check the information and try again.' : 'Invalid SSN - Please call Pet Advocate number listed in top of the screen.' }}</p>
                            </div>
                        </div>
                    </div>
                    <vue-recaptcha
                        class="mt-2"
                        ref="recaptcha"
                        :sitekey="siteKey"
                        @verify="onCaptchaVerified"
                        badge="inline"
                        size="invisible"
                        v-if="ssnFunctActive()"
                    />

                    <div class="v-messages theme--light" v-if="contactBillingEmployeeIdTipsBlockHtml">
                        <div class="v-messages__wrapper">
                            <div id="breedTypeMessages" class="v-messages__message d-flex flex-row align-start">
                                <v-icon class="mr-2 info--text" large>mdi-information-slab-circle</v-icon>
                                <span class="body-2" v-html="contactBillingEmployeeIdTipsBlockHtml"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </v-form>
        </v-card>    

        <v-card flat>
            <v-overlay z-index="3" absolute :value="showPartnerSearch" color="white" opacity="0.6"></v-overlay>
            <v-overlay z-index="3" absolute :value="showPaymentOverlay" color="white" opacity="0.6"></v-overlay>
            <v-form v-show="partner.billingOptions == 'directBill' || payOption == 'creditCard'">
                <v-row>
                    <v-col>
                        <h2 id="paymentFormTitle" tabindex="0">Credit Card Information</h2>
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12">
                        <div class="stripe-field" id="card-number"></div>
                    </v-col>

                    <v-col cols="6">
                        <div class="stripe-field" id="card-expiry"></div>
                    </v-col>

                    <v-col cols="6">
                        <div class="stripe-field" id="card-cvc"></div>
                    </v-col>

                    <v-col cols="12">
                        <div id="card-error"></div>
                    </v-col>
                </v-row>
                <v-row class="d-flex flex-row">
                    <v-img 
                        alt="Stripe"
                        class="shrink my-4"
                        contain
                        src="@/assets/images/stripe.png"
                        transition="scale-transition"
                        max-height="120"
                        position="left"
                        width="auto"
                    />
                </v-row>
            </v-form>
           
            
        </v-card>
        
        <provider></provider>
    </div>
</template>

<script>
    import moment from 'moment';
    import { requiredIf } from 'vuelidate/lib/validators';
    import UserEmployeer from '../../components/global/Partner';
    import Provider from '../../components/global/Provider.vue';
    import Swal from 'sweetalert2';
    import { VueRecaptcha } from 'vue-recaptcha';
    export default {
        name: "QFPaymentInfo",
        components: { UserEmployeer, Provider, VueRecaptcha },
        validations: {
            contact: {
                ssn: {
                    requiredIf: requiredIf(function () {
                        return this.$route.query["LeadId"] ? false : this.partner.isSsnRequired;
                    })
                },
                employeeId: {
                    requiredIf: requiredIf(function () {
                        return this.$route.query["LeadId"] ? false : this.partner.isEmpIdRequired;
                    })
                }
            }
        },
        data() {
            return {
                cardNumber: null,
                cardExpiry: null,
                cardCvc: null,
                showPartnerSearch: false,
                validEmployer: false,
                showPaymentOverlay: false,
                paymentMethod: null,
                paymentMethods: [{id: "creditCard", name: "Credit Card"}, {id: "payrollDeduct", name: "Payroll Deduction"}],
                rules: [
                    value => !!value || "Please select your payment method. (Required).",
                ],
                formValidPaymentMethod: true,
                employeeIdFailedAttempt: false,
                ssnFailedAttempt: false,
                ssnFailedFormat: false,
                siteKey: process.env.VUE_APP_GOOGLE_SITE_KEY,
                recaptchaToken: null,
                showAttempsDialog: false,
            };
        },
        computed: {
            partnerId: function () {
                return this.$store.getters.partnerId;
            },
            partner: function () {
                return this.$store.getters.partner;
            },
            employeeToken: function () {
                return this.$store.getters.employeeToken;
            },
            isFamilyPlan() {
                return this.$store.getters.isFamilyPlan;
            },
            payOption() {
                this.checkPayOption(this.$store.getters.payOption);
                return this.$store.getters.payOption;
            },
            stripeElements() {
                return this.$stripe.elements();
            },
            contact: function () {
                return this.$store.getters.checkoutContact;
            },
            pets: function () {
                return this.$store.getters.pets;
            },
            paymentFrequency: function () {
                return this.$store.getters.paymentFrequency;
            },
            SSNErrors: function () {
                const errors = [];
                if (!this.$v.contact.ssn.$dirty) return errors
                !this.$v.contact.ssn.requiredIf && errors.push("Please Enter your Social Security Number.");
                this.ssnFailedAttempt && errors.push("");
                return errors;
            },
            EmployeeIdErrors: function () {
                const errors = [];
                if (!this.$v.contact.employeeId.$dirty) return errors
                !this.$v.contact.employeeId.requiredIf && errors.push("Please Enter your Employee Id.");
                !this.isAlphanumericInput && errors.push("Only alphanumeric characters are allowed.");
                this.employeeIdFailedAttempt && errors.push("");
                return errors;
            },
            hideGSSPSSN: function(){
                return this.$store.getters.hideGSSPSSN;
            },
            hideGSSPEmployeeId: function(){
                return this.$store.getters.hideGSSPEmployeeId;
            },
            leadId() {
                return this.$store.getters.leadId;
            },
            project() {
                return this.$store.getters.getProject;
            },
            isMetGen(){
                return this.$store.getters.isMetGen;
            },
            employeeToken: function() {
                return this.$store.getters.employeeToken;
            },
            employeeSearchCount() {
                return this.$store.getters.getEmployeeSearchCount;
            },
            payOption: function () {
                return this.$store.getters.payOption;
            },
            selectedPolicies() {
                return this.$store.getters.selectedPolicies;
            },
            isAlphanumericInput() {
                const alphanumericRegex = /^[A-Za-z0-9]+$/;
                return alphanumericRegex.test(this.contact.employeeId);
            },
            currentPet() {
                return this.$store.getters.currentPet;
            },
            emptySsn() {
                return this.$store.getters.isEmptySsn;
            },
            dynamicContent() {
                return this.$store.getters.getDynamicContent.DynamicJson;
            },
            contactBillingEmployeeIdTipsBlockHtml(){
                return this.dynamicContent && this.dynamicContent?.contactBillingEmployeeIdTipsBlockHtml 
                    ? this.dynamicContent.contactBillingEmployeeIdTipsBlockHtml 
                    : ""; 
            },
            validateSSN() {
                if(this.partner.keyIdValidation === null) return true;

                return (this.partner.primaryKeyId === 'SSN' && this.partner.keyIdValidation === 1)
                    || (this.partner.secondaryKeyId === 'SSN' && this.partner.keyIdValidation === 2)
                    || this.partner.keyIdValidation === 0;
            },
            validatePartnerID() {
                if(this.partner.keyIdValidation === null) return true;

                return (this.partner.primaryKeyId === 'Employee Id' && this.partner.keyIdValidation === 1)
                    || (this.partner.secondaryKeyId === 'Employee Id' && this.partner.keyIdValidation === 2)
                    || this.partner.keyIdValidation === 0;
            },
            enrollmentCode: {
                get() {
                    return this.$store.getters.enrollmentCode;
                },
                set(value) {
                    this.$store.commit("saveEnrollmentCode", value);
                }
            },
            captureSSN() {
                const ssnCapture = this.$store.getters.getDynamicContent.SsnCapture;
                return ssnCapture === "1";
            },
            ssnLabel() {
                return this.ssnFunctActive() ? "Social Security Number - Last 4 Digits Only" : "Social Security Number";
            },
            ssnMask() {
                return this.ssnFunctActive() ? '####' : '#########';
            },
            groupWithEligVerifDateActive() {
                if (this.partner.eligibilityVerificationDate && this.partner.eligibilityVerificationDate !== '0001-01-01T00:00:00') {
                    const eligibilityVerificationDate = moment(this.partner.eligibilityVerificationDate);
                    const today = moment().startOf('day');
    
                    if (eligibilityVerificationDate.isAfter(today)) {
                        return true;
                    }
                }

                return false;
            },
        },
        async created() {
            if (this.payOption === "both") this.showPaymentOverlay = true;
            if (this.payOption !== "both") this.paymentMethod = this.payOption;
        },
        mounted() {
            this.initDOMStripeElements();
            let self = this;

            // FOR GSSP IF PAYROLL DEDUCT AND HAS ALL INFO 
            // MOVE TO CONFIRMATION PAGE
            if(this.partner.billingOptions === 'payrollDeduct' || this.payOption === 'payrollDeduct'){
                let needsBoth = false;
                if(this.partner.isSsnRequired && this.partner.isEmpIdRequired){
                    needsBoth = true;
                }

                var paymentRequestList = [];
                var paymentMethod = this.employeeToken == undefined ? 2 : 9;
                if (this.isFamilyPlan) {
                    paymentRequestList = [{
                        contact: self.contact,
                        pets: self.getAllPetsRequest(),
                        petIndex: 0,
                        token: null,
                        paymentFrequency: self.paymentFrequency,
                        paymentMethod: paymentMethod
                    }];
                } else {
                    self.pets.forEach(function (petValue, petIndex) {
                        let petRequest = [];
                        petRequest.push(self.getSubmitPetRequest(petValue));

                        paymentRequestList.push({
                            contact: self.contact,
                            pets: petRequest,
                            petIndex: petIndex,
                            token: null,
                            paymentFrequency: self.paymentFrequency,
                            paymentMethod: paymentMethod
                        });
                    });
                }

                if(needsBoth){
                    if(this.hideGSSPSSN && this.hideGSSPEmployeeId){
                        this.$emit("setPaymentRequest", paymentRequestList);
                        this.$emit("setPaymentRequestList", paymentRequestList);
                    }else if(!this.partner.isEmpIdRequired && !this.partner.isSsnRequired){
                        this.$emit("setPaymentRequest", paymentRequestList);
                        this.$emit("setPaymentRequestList", paymentRequestList);
                    }
                }else{
                    if(this.partner.isSsnRequired && this.hideGSSPSSN){
                        this.$emit("setPaymentRequest", paymentRequestList);
                        this.$emit("setPaymentRequestList", paymentRequestList);
                    }else if(this.partner.isEmpIdRequired && this.hideGSSPEmployeeId){
                        this.$emit("setPaymentRequest", paymentRequestList);
                        this.$emit("setPaymentRequestList", paymentRequestList);
                    }else if(!this.partner.isEmpIdRequired && !this.partner.isSsnRequired){
                        this.$emit("setPaymentRequest", paymentRequestList);
                        this.$emit("setPaymentRequestList", paymentRequestList);
                    }
                }
            }
        },
        methods: {
            checkPayOption(value){
                let self = this;
                if(value === 'payrollDeduct'){
                    let needsBoth = false;
                    if(this.partner.isSsnRequired && this.partner.isEmpIdRequired){
                        needsBoth = true;
                    }

                    var paymentRequestList = [];
                    var paymentMethod = this.employeeToken == undefined ? 2 : 9;
                    if (this.isFamilyPlan) {
                        paymentRequestList = [{
                            contact: self.contact,
                            pets: self.getAllPetsRequest(),
                            petIndex: 0,
                            token: null,
                            paymentFrequency: self.paymentFrequency,
                            paymentMethod: paymentMethod
                        }];
                    } else {
                        self.pets.forEach(function (petValue, petIndex) {
                            let petRequest = [];
                            petRequest.push(self.getSubmitPetRequest(petValue));

                            paymentRequestList.push({
                                contact: self.contact,
                                pets: petRequest,
                                petIndex: petIndex,
                                token: null,
                                paymentFrequency: self.paymentFrequency,
                                paymentMethod: paymentMethod
                            });
                        });
                    }

                    if(needsBoth){
                        if(this.hideGSSPSSN && this.hideGSSPEmployeeId){
                            this.$emit("setPaymentRequest", paymentRequestList);
                            this.$emit("setPaymentRequestList", paymentRequestList);
                        }else if(!this.partner.isEmpIdRequired && !this.partner.isSsnRequired){
                            this.$emit("setPaymentRequest", paymentRequestList);
                            this.$emit("setPaymentRequestList", paymentRequestList);

                        }
                    }else{
                        if(this.partner.isSsnRequired && this.hideGSSPSSN){
                            this.$emit("setPaymentRequest", paymentRequestList);
                            this.$emit("setPaymentRequestList", paymentRequestList);
                        }else if(this.partner.isEmpIdRequired && this.hideGSSPEmployeeId){
                            this.$emit("setPaymentRequest", paymentRequestList);
                            this.$emit("setPaymentRequestList", paymentRequestList);
                        }else if(!this.partner.isEmpIdRequired && !this.partner.isSsnRequired){
                            this.$emit("setPaymentRequest", paymentRequestList);
                            this.$emit("setPaymentRequestList", paymentRequestList);
                        }
                    }
                }
            },
            async submit(submitMethod, contactInfoRef) {
                const self = this;
                const cardOptions = this.getCardOptions();
                const paymentMethod = this.employeeToken == undefined ? 2 : 9;
                const employerSelect = this.$refs.userEmployer?.$refs.companySelectAutocomplete;
                const employerPaymentMethod = this.$refs.PaymentMethodForm;

                let paymentRequestList = [];

                if(submitMethod === "PayrollDeduct") this.contact.ssn = this.formatSocialSecurityNumber(this.contact.ssn);

                if (this.isFamilyPlan) {
                    const { token, error } = submitMethod === "CardToStripe" ? await self.$stripe.createToken(this.cardNumber, cardOptions) : {};
                    if (error) {
                        document.getElementById('card-error').innerHTML = error.message;
                        return;
                    }

                    paymentRequestList = [{
                        contact: self.contact,
                        pets: self.getAllPetsRequest(),
                        petIndex: 0,
                        token: token ? token : null,
                        paymentFrequency: self.paymentFrequency,
                        paymentMethod: paymentMethod
                    }];
                } else {
                    for(let i=0; i<self.pets.length; i++) {
                        const { token, error } = submitMethod === "CardToStripe" ? await self.$stripe.createToken(this.cardNumber, cardOptions) : {};

                        if (error) {
                            document.getElementById('card-error').innerHTML = error.message;
                            return;
                        }

                        let petRequest = [];

                        submitMethod === "CardToStripe" 
                            ? petRequest = self.getIndividualPetRequest(i)
                            : petRequest.push(self.getSubmitPetRequest(self.pets[i]));

                        paymentRequestList.push({
                            contact: self.contact,
                            pets: petRequest,
                            petIndex: i,
                            token: token ? token : null,
                            paymentFrequency: self.paymentFrequency,
                            paymentMethod: paymentMethod
                        });
                    }
                }

                this.focusErrorField();
                if(document.getElementById('card-error')) document.getElementById('card-error').innerHTML = "";

                var species;
                if (self.pets[0].species == 1) {
                    species = 'Dog';
                }
                else if (self.pets[0].species == 2) {
                    species = 'Cat';
                }
                else {
                    species = 'Exotic';
                }

                if(this.project === "legacy") {
                    let ldo = {
                        eventName: 'quoteStep',
                        attributes: { 
                        application: 'MLPI',
                            form: 'Pet Insurance Quote',
                            stepName: 'Payment Information',
                            leadId: this.leadId,
                            petType: species
                        }
                    };

                    ldo = this.popullateFederalAnalyticProperty(ldo);

                    this.$store.dispatch("trackAnalyticsEvent",{
                        eventType: "quoteStep",
                        ldo: ldo
                    });
                }

                /**
                 * if there is any error on the fields return before
                 * doing setting the payment request list.
                 */
                if (this.$v.$invalid) return false;
                if (this.partner.id) {
                    if (employerSelect) if (!employerSelect.valid) return false;
                    if (employerPaymentMethod) if (!employerPaymentMethod.inputs[0].valid) return false;
                }
                if(this.project === "challenger" && contactInfoRef) if (contactInfoRef.$v.$invalid) return false;

                if(this.partner.isSsnRequired || this.captureSSN) {
                    let count = this.employeeSearchCount;
                    
                    if(this.ssnFunctActive()) { 
                        if(count < 4 && this.recaptchaToken === null) {
                            this.$refs.recaptcha.execute();

                            this.$emit("disableNextParentBtn", true);
                            const recaptchaVerified = new Promise(resolve => {
                                this.$once('recaptcha-verified', resolve);
                            });
                            
                            await recaptchaVerified;
                            this.$emit("disableNextParentBtn", false);
                        }

                        if(count < 4) {
                            const ssnValue = this.$refs.ssnField.$el.querySelector('input').value;
                            if(ssnValue.length !== 4) {
                                this.$store.commit("setEmployeeSearchCount", count += 1);
                                this.ssnFailedAttempt = true;

                                if(this.employeeSearchCount == 4) {
                                    if(this.ssnPopupFunctionality() && this.enrollmentCode !== '99-99-00-0002') this.showAttempsDialog = true;
                                    this.$emit("disableNextParentBtn", true);
                                    this.employeeIdFailedAttempt = false;
                                }
                                return;
                            } else {
                                this.contact.ssn = ssnValue;
                            }
                        } else {
                            this.$emit("disableNextParentBtn", true);
                            this.ssnFailedAttempt = true;
                            this.employeeIdFailedAttempt = false;
                            return;
                        }
                    } else {
                        if(count < 4) {
                            if(!this.validSSNpattern()) {
                                this.$store.commit("setEmployeeSearchCount", count += 1);
                                this.ssnFailedAttempt = true;
                                this.ssnFailedFormat = true;

                                if(this.employeeSearchCount == 4) {
                                    if(this.ssnPopupFunctionality() && this.enrollmentCode !== '99-99-00-0002' && !this.groupWithEligVerifDateActive) this.showAttempsDialog = true;
                        
                                    this.$emit("disableNextParentBtn", true);
                                    this.employeeIdFailedAttempt = false;
                                }
                                return;
                            } else {
                                this.ssnFailedFormat = false;
                            }
                        } else {
                            if(this.ssnPopupFunctionality() && this.enrollmentCode !== '99-99-00-0002' && !this.groupWithEligVerifDateActive) this.showAttempsDialog = true;

                            this.$emit("disableNextParentBtn", true);
                            this.ssnFailedFormat = false;
                            this.ssnFailedAttempt = true;
                            this.employeeIdFailedAttempt = false;
                            return;
                        }
                    }
                }
                
                if (!this.partner.id || this.employeeSearchCount > 2 || (!this.partner.isSsnRequired && !this.partner.isEmpIdRequired) || this.partner.keyIdValidation === null || this.$route.query["LeadId"] || this.groupWithEligVerifDateActive) {
                    if(this.ssnPopupFunctionality() && this.enrollmentCode !== '99-99-00-0002' && this.partner.id && (this.partner.isSsnRequired || this.partner.isEmpIdRequired) && this.partner.keyIdValidation !== null && !this.$route.query["LeadId"] && !this.groupWithEligVerifDateActive) {
                        this.showAttempsDialog = true;
                        this.$emit("disableNextParentBtn", true);
                        this.employeeIdFailedAttempt = false;
                        return;
                    }

                    if (this.employeeSearchCount > 2) this.$store.commit("setSearchEmployeeAgain", true);
                    await this.$store.commit("setPaymentRequestList", paymentRequestList);
                    this.$emit("setPaymentRequest", paymentRequestList);
                    this.$emit("setPaymentRequestList", paymentRequestList);
                    return;
                } else {
                    let count = this.employeeSearchCount;

                    this.$emit("setEmployeeInfoLoader", true);
                    const employeeInfo = await this.getEmployeeInfo();
                    this.$emit("setEmployeeInfoLoader", false);

                    if(employeeInfo) {
                        this.$store.commit("setEmployeeSearchActive", true);

                        if (employeeInfo.status === "RecordFound") {
                            this.saveEmployeeInfo(employeeInfo.cbsEmployeeId, employeeInfo.isPetProductEligible);
                            this.savePayRequestList(paymentRequestList);
                            if(this.ssnFunctActive()) { 
                                this.$refs.recaptcha.reset();
                                this.recaptchaToken = null;
                            }
                            return;
                            
                        } else {
                            let ldo = {
                                eventName: 'quoteError',
                                attributes: { 
                                application: 'MLPI',
                                    form:'Pet Insurance Quote',
                                    stepName: 'Contact & Billing Info',
                                    ErrorMessage: 'Invalid Employee Information',
                                    screenName: `Invalid Info error ${count + 1}`,
                                    leadId: this.leadId,
                                    petType: this.getPetsSpecies(this.pets),
                                    standardWellness: this.getStandardWellness(this.selectedPolicies, this.isFamilyPlan, this.pets),
                                    familyPlan: this.isFamilyPlan ? 'Yes' : 'No',
                                }
                            }

                            ldo = this.popullateFederalAnalyticProperty(ldo);

                            digitalData.eventTrack('quoteError', ldo);

                            if(this.validateSSN && (employeeInfo.incorrectValue === null || employeeInfo.incorrectValue.includes(0))) this.ssnFailedAttempt = true;
                            if(this.validatePartnerID && (employeeInfo.incorrectValue === null || employeeInfo.incorrectValue.includes(1))) this.employeeIdFailedAttempt = true;

                            this.$store.commit("setEmployeeSearchCount", count += 1);
                            if(this.ssnFunctActive()) { 
                                this.$refs.recaptcha.reset();
                                this.recaptchaToken = null;
                            }
                            return;
                        } 
                    } else {
                        this.$store.commit("setEmployeeSearchActive", false);
                        this.savePayRequestList(paymentRequestList);
                        if(this.ssnFunctActive()) { 
                            this.$refs.recaptcha.reset();
                            this.recaptchaToken = null;
                        }
                        return;
                    }

                }   
            },
            clearErrorOnChange(){
                if(this.employeeIdFailedAttempt){
                    this.employeeIdFailedAttempt = false;
                }
            },
            clearSSNErrorOnChange(){
                if(this.ssnFailedAttempt){
                    this.ssnFailedAttempt = false;
                }
            },
            async savePayRequestList(paymentRequestList) {
                await this.$store.commit("setPaymentRequestList", paymentRequestList);
                this.$emit("setPaymentRequest", paymentRequestList);
                this.$emit("setPaymentRequestList", paymentRequestList);
                return;
            },
            initDOMStripeElements: function () {
                this.cardNumber = this.stripeElements.create('cardNumber');
                this.cardNumber.mount('#card-number');

                this.cardExpiry = this.stripeElements.create('cardExpiry');
                this.cardExpiry.mount('#card-expiry');

                this.cardCvc = this.stripeElements.create('cardCvc');
                this.cardCvc.mount('#card-cvc');
            },
            getCardOptions: function () {
                var cardOptions = {
                    name: this.contact.firstName + " " + this.contact.lastName,
                    currency: "usd"
                };

                if (this.contact.sameAddress === "true") {
                    cardOptions.address_line1 = this.contact.address1;
                    cardOptions.address_line2 = this.contact.address2;
                    cardOptions.address_city = this.contact.city;
                    cardOptions.address_state = this.contact.state;
                    cardOptions.address_zip = this.contact.zip;
                    cardOptions.address_country = this.contact.country;
                } else {
                    cardOptions.address_line1 = this.contact.billing.address1;
                    cardOptions.address_line2 = this.contact.billing.address2;
                    cardOptions.address_city = this.contact.city;
                    cardOptions.address_state = this.contact.state;
                    cardOptions.address_zip = this.contact.billing.zip;
                    cardOptions.address_country = this.contact.country;
                }

                return cardOptions;
            },
            formatSocialSecurityNumber(ssn) {
                var numbers = ssn.replace(/[^0-9]/g, "");
                if (numbers.length == 9) {
                    ssn =
                        numbers.substr(0, 3) +
                        "-" +
                        numbers.substr(3, 2) +
                        "-" +
                        numbers.substr(5, 4);
                }
                return ssn;
            },
            getSubmitPetRequest(pet) {
                let petObject = {};

                petObject.BreedId = pet.breed != "" ? pet.breed : pet.species == 1 ? 54 : 2;
                petObject.Species = pet.species;
                petObject.PetName = pet.name;
                petObject.BirthDate = moment().subtract(pet.age, "years").format("YYYY-MM-DD");
                petObject.Gender = pet.gender.toString();
                petObject.WeightId = pet.mixed ? (pet.weight ? pet.weight : 1) : 1;
                if(pet.providerId !== null){
                    petObject.PreviousCoverage = pet.providerId;
                    petObject.PreviousCoverageName = pet.previousCoverage;
                }

                return petObject;
            },
            getAllPetsRequest() {
                var self = this;

                let petList = [];
                this.pets.map(function (pet) {
                    let petObject = self.getSubmitPetRequest(pet);
                    petList.push(petObject);
                });
                return petList;
            },
            getIndividualPetRequest(petIndex) {
                var self = this;

                let petList = [];
                this.pets.map(function (pet, index) {

                    if (index == petIndex) {
                        let petObject = self.getSubmitPetRequest(pet);
                        petList.push(petObject);
                    }
                });
                return petList;
            },
            async generateSubmissionRequest(contactInfoRef) {
                const employerSelect = this.$refs.userEmployer?.$refs.companySelectAutocomplete;
                const employerPaymentMethod = this.$refs.PaymentMethodForm;

                this.$v.$touch();
                this.stripeErrorsFocus();

                if (this.partner.id) {
                    if (employerSelect) employerSelect.validate();
                    if (employerPaymentMethod) employerPaymentMethod.validate();
                }

                if (this.partner.billingOptions == 'payrollDeduct' || this.payOption == 'payrollDeduct') {
                    await this.submit("PayrollDeduct", contactInfoRef);
                }

                if (this.partner.billingOptions == 'directBill' || this.payOption == 'creditCard') {
                   await this.submit("CardToStripe", contactInfoRef);
                }
            },
            stripeErrorsFocus() {
                this.cardNumber.update();
                if (this.cardNumber._invalid || this.cardNumber._empty) {
                    this.cardNumber.focus();
                } else if (this.cardExpiry._invalid || this.cardExpiry._empty) {
                    this.cardExpiry.focus();
                } else if (this.cardCvc._invalid || this.cardCvc._empty) {
                    this.cardCvc.focus();
                }
            },
            setShowPartnerSearch() {
                this.$emit("disableNextParentBtn", true);
                this.showPartnerSearch = true;
            },
            async confirmEmployer() {
                const employerId = this.$refs.userEmployer._data.employee;

                if(employerId && this.partner.id !== employerId) {
                    await this.$refs.userEmployer.getPartnerInfo(employerId);
                    
                    if(this.partner.billingOptions === "both") {
                        this.paymentMethod = null;
                        this.showPaymentOverlay = true;
                        
                        this.$nextTick(() => this.$refs.PaymentMethodForm.validate());
                    }

                    if(this.partner.billingOptions === "directBill" || this.partner.billingOptions === "payrollDeduct") {
                        this.partner.billingOptions === "directBill" 
                            ? this.$store.commit("setPayOption", "creditCard")
                            : this.$store.commit("setPayOption", "payrollDeduct");

                        await this.getFatsQuote();
                        await this.calculateDates();
                        this.showPaymentOverlay = false;
                    } 
                }

                this.$emit("disableNextParentBtn", false);
                this.showPartnerSearch = false;
            },
            setValidEmployer(employerId) {
                if (employerId) {
                    this.validEmployer = true;
                    return;
                }

                this.validEmployer = false;
            },
            async getEmployeeInfo() {
                return await this.$store.dispatch("validateEmployeeInfo", {
                    payload: {
                        "cdfNumber": this.partnerId ? String(this.partnerId) : "",
                        "uisNumber": "",
                        "firstName": this.contact.firstName ? String(this.contact.firstName) : "", 
                        "lastName": this.contact.lastName ? String(this.contact.lastName) : "",
                        "employeeId": this.validatePartnerID && this.contact.employeeId ? String(this.contact.employeeId) : "",
                        "ssn": this.validateSSN && this.contact.ssn ? String(this.contact.ssn) : "",
                    },
                    requireToken: this.ssnFunctActive() ? true : false,
                    token: this.recaptchaToken
                });
            },
            saveEmployeeInfo(cbsEmployeeId, isPetProductEligible) {
                this.$store.commit("setCBSEmployeeID", cbsEmployeeId);
                this.$store.commit("setIsPetProductEligible", isPetProductEligible);
            },
            localValidatePaymentMethod(idx){
                if(idx >= 1){
                    this.$refs.PaymentMethodForm.validate();
                }
            },
            async paymentSelected(value) {
                if(value === "payrollDeduct") this.$store.commit("setPaymentFrequency", 'Monthly');
                this.$store.commit("setPayOption", value);
                await this.getFatsQuote();
                await this.calculateDates();

                this.showPaymentOverlay = false;
            },
            setPlanDetailsHelper(i, quote){
                if(!this.selectedPolicies?.[this.currentPetIndex]?.customPlan) {
                    this.selectedPolicies[i].planDetails = quote;
                }
            },
            getFatsQuote() {
                this.$emit("setGetFastQuoteLoader", true);

                this.$store.dispatch("getCustomizedFastQuotes", { policies: this.selectedPolicies }).then(response => {
                    this.$store.dispatch("getDate");
                    this.$store.dispatch("getDatePlus14");

                    this.selectedPolicies.forEach((policy, index) => {
                        policy.planDetails = {
                            ...policy.planDetails,
                            ...response[index]
                        }
                    })

                    this.$emit("setGetFastQuoteLoader", false);
                }).catch(err => {
                    console.log(err);
                });
            },
            showEmploymentInfo() {
                // TPE
                if(this.$route.query["LeadId"]) return false;

                // GSSP
                if(this.$route.query["token"]) {
                    
                    if (this.partner.isSsnRequired && this.emptySsn) return true;
                    
                    return false;  
                }

                if(this.disablePopupsAffiliate()) return false;

                return true;
            },
            calculateDates() {
                // CALCULATE ACCIDENT AND ILLNESS DATES
                this.$store.dispatch("getDate");
                this.$store.dispatch("getDatePlus14");
            },
            showEditEmployment() {
                if(this.$route.query["token"]) return false;

                //PartnerId 251072
                if(String(this.partnerId) === "251072") return false;
                
                //Partners
                if(this.$route.query["partnerId"] && String(this.$route.query["partnerId"]) !== "999999") return false;

                return true;
            },
            validSSNpattern() {
                const ssnRegex = /^(?!000|666|9\d{2})(?!([0-9])\1{2}-\1{2}-\1{4})\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;

                return ssnRegex.test(this.formatSocialSecurityNumber(this.contact.ssn)) ? true : false;
            },
            onCaptchaVerified(token) {
                this.recaptchaToken = token;
                this.$emit('recaptcha-verified');
            },
            onCaptchaExpired() {
                this.recaptchaToken = null;
            },
            onCaptchaError() {
                this.recaptchaToken = null;
            },
            goBackToRetail() {
                this.clearPartnerObject();
                
                this.$store.commit('clearDynamicContent');
                this.$store.commit("setPartnerLogoSource","");
                sessionStorage.getItem("partnerLogoSource");
                sessionStorage.removeItem('step1SideImgSource');
                sessionStorage.removeItem('step2SideImgSource');
                sessionStorage.removeItem('step3SideImgSource');
                sessionStorage.removeItem('step4SideImgSource');

                if(this.$route.query["partnerId"]) delete this.$route.query.partnerId;
                this.$store.commit("saveEnrollmentCode", "99-99-00-1233");
                this.$route.query["enrollment_code"] = this.$store.getters.enrollmentCode;
                this.$store.commit("saveEmployeeToken", undefined);
                this.$store.commit("setCurrentPage", "Plan Selection");
                this.routerPush("quotes");
            }, 
            closeAttempsDialog() {
                this.showAttempsDialog = false;
            }
        }
    }
</script>